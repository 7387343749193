import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { useAppBreakpoints } from '@peng_kai/theme/vue'

export * from './antd/antInputNumberFormatting'
export {
  fixed,
  sleep,
  client,
  env,
  appendURLQuery,
  desensitization,
  statusFilters,
  orderTypeFilters,
  promiseError,
  hasToken,
  extractContentInBrackets,
  isSameError,
  jumpCompatible,
  currentTimeZone,
}
export * from './antd/antInputNumberFormatting'

dayjs.extend(duration)

/** 客户端 */
const client = {
  get isMobile() {
    return !!(
      typeof navigator !== 'undefined'
      && navigator.userAgent.match(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i,
      )
    )
  },
  get isPc() {
    return !this.isMobile
  },
  get isIos() {
    return !!(
      typeof navigator !== 'undefined'
      && navigator.userAgent.match(/iPhone|iPad/i)
    )
  },
  get isAndroid() {
    return !!(
      typeof navigator !== 'undefined' && navigator.userAgent.match(/Android/i)
    )
  },
}
const env = {
  get dev() {
    return import.meta.env.VITE_BUILD_MODE === 'development'
  },
  //  执行打包都是这个条件
  get prod() {
    return import.meta.env.VITE_BUILD_MODE === 'production'
  },
  get staging() {
    return import.meta.env.VITE_BUILD_MODE === 'staging'
  },
  get test() {
    return this.dev || this.staging
  },
}

if (!import.meta.env.SSR)
  window.env = env

function hasToken() {
  if (import.meta.env.SSR)
    return false
  return document.cookie.includes('has_token=1')
}

/**
 * 四舍五入
 * @param num 数字
 * @param fractionDigits 精确到小数点后几位
 * @param useGrouping 是否加入千分号，如果加入则返回字符串类型
 */
function fixed(num: number, fractionDigits: number, useGrouping?: true): string
function fixed(num: number, fractionDigits: number, useGrouping: false): number
function fixed(num: number, fractionDigits?: number): number
function fixed(
  num: number,
  fractionDigits = 0,
  useGrouping = true,
): number | string {
  const intl = new Intl.NumberFormat('en-GB', {
    maximumFractionDigits: fractionDigits,
    useGrouping,
  })
  const formated = intl.format(num)

  return useGrouping ? formated : Number(formated)
}

/**
 * 睡眠
 * @param dur 持续时长，单位毫秒
 */
function sleep(dur: number) {
  return new Promise(resolve => setTimeout(resolve, dur))
}

/**
 * 添加 URL 查询参数
 * @param url
 * @param values
 */
function appendURLQuery(
  url: string,
  values: Array<{ key: string; value: string | number }>,
) {
  try {
    const urlObj = new URL(url)

    values.forEach(item =>
      urlObj.searchParams.set(item.key, String(item.value)),
    )

    return urlObj.toString()
  }
  catch {

  }
}

/**
 * 脱敏函数
 * @param str
 * @param beginLen
 * @param endLen
 */
function desensitization(str: string, beginLen: number, endLen: number) {
  const firstStr = str.slice(0, beginLen)
  const lastStr = str.slice(endLen)
  // const middleStr = str.substring(beginLen, len - Math.abs(endLen)).replace(/[\s\S]/gi, '*');
  const middleStr = '***'
  return firstStr + middleStr + lastStr
}

/**
 * 订单状态过滤
 * @param status
 */
function statusFilters(status: number): string {
  const orderStatusMap: { [kye: number]: string } = {
    1: $t('DeW24Y46nMqe5Gvzf2EbZ'),
    2: $t('AvgRu82f_vS0iuuxi_K0B'),
    3: $t('_GyPSuc2M4lSEHOIxzZow'),
    4: $t('9eTFiFam4W3UVB40c-z0g'),
    5: $t('iAwHKejz795Us9yJ9gBcq'),
    6: $t('TSxJd4GTZfry_EEN0ET4P'),
    7: $t('PKLC17lvQausklv_7WTXA'),
    8: $t('kcMcvMx--g-8gh6rueoDM'),
    9: $t('2IIp-fY_TapvIvnET8Svq'),
    10: $t('4RUkC3O9Yvj7hJlWPT5Iu'),
    12: $t('QcN8o-8Sj33ro7NAQ3845'),
    13: $t('tHhDlmxZHWzsfxMjCkQp8'),
    14: $t('fN7aa_B6E19Hnr61JG_I4'),
    15: $t('ykI42CpTlPgQx5MwB4Ae'),
  }
  return orderStatusMap[status]
}

/**
 * 订单类型过滤
 * @param status
 */
function orderTypeFilters(key: number): string {
  const type: { [kye: number]: string } = {
    1: $t('syeMRIeBr3TkMUaTmUyEH'),
    2: $t('yiSrOfcmDFQ4S_64vwKmE'),
    3: $t('yQa8lk0lZ8K9lFtVnQpy'),
    4: $t('rOV0u1yI2bxbz3pvTu0d'),
  }
  return type[key]
}

/**
 * 兼容租用时长自定义情况
 * @param seconds
 */
export function formatSecond(seconds: number) {
  const duration = dayjs.duration(seconds, 'seconds')
  const second
    = duration.seconds() > 0
      ? duration.seconds() + $t('tcfd5N_eXSTa-gEOHqdlZ')
      : '' // 获取秒
  const minutes
    = duration.minutes() > 0
      ? duration.minutes() + $t('ojfXwyNQvnnvZ-Vsa3nxF')
      : '' // 获取分钟
  const hours
    = duration.hours() > 0 ? duration.hours() + $t('PcVyQygfSsCLRWYyg8MuL') : '' // 获取小时数
  const days
    = duration.days() > 0 ? duration.days() + $t('nddsLvy6J2rW722Swgcfi') : '' // 获取天数
  const months = duration.months() > 0 ? $t('XjOaWw_YIbdAbzojLv8uV') : '' // 获取月
  return months
    ? months + hours + minutes + second
    : days + hours + minutes + second
}

/**
 * 显示时间
 * @param dur
 * @param unit
 */
export function timeFn(dur: number, unit: string) {
  const hour = dur > 1 ? $t('GRAxQVNTjLwXYfOpY6FRh') : $t('-VXE8U3ypH51xAowQGH8d')
  const day = dur > 1 ? $t('8zE4c0R_g37KkcLgrn4u0') : $t('sgq3K4HMvzvjpCGigTbMu')
  const unitStr = unit === 'h' ? hour : day
  return dur + unitStr
}

/**
 * 换算
 * @description:
 * @param {*} num 转换的数字
 * @param {*} n 换算的10的倍数
 * @param {*} decimalPlaces 精度
 */
export function multiplyByTenToPower(
  num: number,
  n: number,
  decimalPlaces = 6,
) {
  return (
    Math.round(
      (num * 10 ** n + Number.EPSILON) * 10 ** decimalPlaces,
    ) / 10 ** decimalPlaces
  )
}
type TOrder = NonNullable<Api.GetDataItem<typeof apis.orderTrades>[number] | undefined>

/**
 * 计算年化率
 * @param record
 */
export function calcAPY(record: TOrder) {
  const formula = record.rent_time_unit === 'h' ? ((record.rent_duration / 24) + 1) : (record.rent_duration + 1)
  const res = (record.max_payout / record.max_freeze / formula) * 365 * 100

  if (isNaN(res))
    return `${0}%`

  const turn = res.toFixed(2)
  return `${turn}%`
}

function promiseError(msg: string) {
  return Promise.reject(new Error(msg))
}

function extractContentInBrackets(txt: string) {
  const regex = /\[(.*?)\]/g
  const matches = []
  let match

  while ((match = regex.exec(txt)) !== null)
    matches.push(match[1])

  return matches
}

function jumpCompatible(url: string) {
  const bp = useAppBreakpoints()
  if (bp.ltLaptop)
    location.href = url
  else
    window.open(url, '_blank')
}

function currentTimeZone() {
  const now = new Date()
  const [_, time, tz, describe] = now.toString().match(/(\w{3} \w{3} \d{2} \d{4} \d{2}:\d{2}:\d{2})? (GMT[+-]\d{4}) \((.*?)\)/) ?? []
  const tzOffset = now.getTimezoneOffset() / 60
  return toBase64([time, tz, tzOffset, describe].join('|'))
}
