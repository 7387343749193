<script lang="ts" setup>
import pkg from '../package.json'
import { useSetSiteTitle } from '~/composables/imtoken/useSetSiteTitle'
import { AppProvider } from '~/components/app-provider'
import { plugin as updateAppPlugin } from '~/vue-plugins/UpdateApp'

useSetSiteTitle()

// 异步加载全局弹窗
const GlobalModalsRegister = defineAsyncComponent(async () => {
  const module = await import('~/components/modal')
  return module.GlobalModalsRegister
})

const { t: $t } = useI18n()
useHead({
  title: () => $t('frQunJ0hl7XTHy3-YC9jW'),
  meta: [
    { name: 'viewport', content: 'width=device-width, initial-scale=1.0,minimum-scale=1.0, maximum-scale=1.0' },
    { name: 'keywords', content: () => $t('En9D2qxTjKc4bsOfAMO5M') },
    { name: 'description', content: () => $t('va2l6qnwD615aEMKBU2JQ') },
  ],
})

const { vueApp } = useNuxtApp()

if (!import.meta.env.SSR)
  vueApp.use(updateAppPlugin, { version: pkg.version })
</script>

<template>
  <NuxtLayout>
    <div>
      <AppProvider>
        <ClientOnly>
          <NuxtLoadingIndicator>
            <GlobalModalsRegister />
          </NuxtLoadingIndicator>
        </ClientOnly>
        <NuxtPage />
      </AppProvider>
    </div>
  </NuxtLayout>
</template>

<style lang="scss">
@import './assets/styles/index';

.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
  filter: blur(1rem);
  opacity: 0;
}

.rotate-enter-active,
.rotate-leave-active {
  transition: all 0.4s;
}

.rotate-enter-from,
.rotate-leave-to {
  opacity: 0;
  transform: rotate3d(1, 1, 1, 15deg);
}
</style>
