import { SentryUtils } from '@/utils/sentry'

const scope: SentryUtils = new SentryUtils('tronError')
export const tronErrors = {
  /** 未发现目标钱包 */
  NOT_FOUND_WALLET: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[NOT_FOUND_WALLET] 未发现目标钱包')
  },
  /** 未发现 Provider */
  NOT_FOUND_PROVIDER: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[NOT_FOUND_PROVIDER] 未发现 Provider')
  },
  /** 不支持此 Chain */
  NOT_SUPPORTED_CHAIN: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[NOT_SUPPORTED_CHAIN] 不支持此 Chain')
  },
  /** 不支持此 Token */
  NOT_SUPPORTED_TOKEN: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[NOT_SUPPORTED_TOKEN] 不支持此 Token')
  },
  /** 不支持此操作 */
  NOT_SUPPORTED_OPERATION: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[NOT_SUPPORTED_OPERATION] 不支持此操作')
  },
  /** 连接钱包被取消 */
  CONNECT_WALLET_REJECTED: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[CONNECT_WALLET_REJECTED] 连接钱包被取消')
  },
  /** 同一个 DApp 已经启动了连接钱包的请求，并且弹出窗口尚未关闭 */
  CONNECT_WALLET_NOT_CLOSED: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[CONNECT_WALLET_NOT_CLOSED] 连接钱包窗口未关闭')
  },
  /** 请求太过频繁 */
  CONNECT_REQUEST_FREQUENTLY: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[CONNECT_REQUEST_FREQUENTLY] 连接操作过于频繁')
  },
  /**
   * 当：
   * 1. 钱包已锁定;
   * 2. 钱包首次安装，并且没有钱包帐户;
   */
  WALLET_LOCKED: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[WALLET_LOCKED] 钱包已锁定')
  },
  /** 连接钱包错误 */
  CONNECT_WALLET_ERROR: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[CONNECT_WALLET_ERROR] 连接钱包错误')
  },
  /** 切换网络被取消 */
  SWITCH_CAHIN_REJECTED: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[SWITCH_CAHIN_REJECTED] 切换网络被取消')
  },
  /** 切换网络错误 */
  SWITCH_CAHIN_ERROR: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[SWITCH_CAHIN_ERROR] 切换网络错误')
  },
  /** 获取网络信息错误 */
  GET_CAHIN_ERROR: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[GET_CAHIN_ERROR] 获取网络错误')
  },
  /** 不支持此网络 */
  NOT_SUPPORTED_CAHIN: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[NOT_SUPPORTED_CAHIN] 不支持此网络')
  },
  /** 获取账户地址错误 */
  GET_ADDRESS_ERROR: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[GET_ADDRESS_ERROR] 获取账户地址错误')
  },
  /** 获取余额错误 */
  GET_BALANCE_ERROR: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[GET_BALANCE_ERROR] 获取余额错误')
  },
  /** 转账被取消 */
  TRANSFER_REJECTED: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[TRANSFER_REJECTED] 转账被取消')
  },
  /** 转账错误 */
  TRANSFER_ERROR: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[TRANSFER_ERROR] 转账错误')
  },
  /** 超时 */
  TRANSACTION_EXPIRATION_ERROR: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[TRANSACTION_EXPIRATION_ERROR] 订单超时')
  },
  /** 未激活 */
  WALLET_NOT_ACTIVATED: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[WALLET_NOT_ACTIVATED] 钱包未激活')
  },
  /** 多签操作异常 */
  MULTISIGN_ERROR: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[MULTISIGN_ERROR] 多签钱包无权限')
  },
  /** 多签操作异常非pc端 */
  MULTISIGN_ERROR_UNPC: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[MULTISIGN_ERROR_UNPC] 多签模式暂不支持DAPP浏览器')
  },
  /** 未获取签名信息 */
  SIGN_ERROR: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[SIGN_ERROR] 未获取签名信息')
  },
  /** 资源不足 */
  RESOURCE_INSUFFICIENT_ERROR: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[RESOURCE_INSUFFICIENT_ERROR] 您的TRX余额不足')
  },
  /** 资源不足 */
  BANDWIDTH_INSUFFICIENT_ERROR: (err?: any) => {
    if (err)
      scope.captureMessage(err)
    return new Error('[RESOURCE_INSUFFICIENT_ERROR] 您的带宽余额不足')
  },

}

export function isSameError(errorFn: () => Error, error: any) {
  return errorFn().message === error?.message
}

export function formatTronErrorMessage(err: () => Error) {
  console.log('err',err);
  let msg = $t('0oxmfDXjua5qBDl0tVvI')
  if (isSameError(tronErrors.NOT_FOUND_WALLET, err))
    msg = $t('x07Is8PffQl9iOeDu5Ywz')

  if (isSameError(tronErrors.NOT_FOUND_PROVIDER, err))
    msg = $t('0gGmZor3eUxy3d3w0VYfA')

  if (isSameError(tronErrors.NOT_SUPPORTED_CHAIN, err))
    msg = $t('vn35twoyOdnkABsqQjJn5')

  if (isSameError(tronErrors.NOT_SUPPORTED_TOKEN, err))
    msg = $t('u4FdCYnOzoYoDcTh2kly')

  if (isSameError(tronErrors.NOT_SUPPORTED_OPERATION, err))
    msg = $t('8A2OVzTfEIvPiYuPsEnIx')

  if (isSameError(tronErrors.CONNECT_WALLET_REJECTED, err))
    msg = $t('8PlhVygj1A0JvchO434qC')

  if (isSameError(tronErrors.CONNECT_WALLET_NOT_CLOSED, err))
    msg = $t('nr3wqorheegdBlO4zMUu')

  if (isSameError(tronErrors.WALLET_LOCKED, err))
    msg = $t('0DcjeChDs1mWaBazF2qKn')

  if (isSameError(tronErrors.CONNECT_WALLET_ERROR, err))
    msg = $t('5mMwSloRiFx0f4Py4GOwz')

  if (isSameError(tronErrors.SWITCH_CAHIN_REJECTED, err))
    msg = $t('aqQHnTQtyBn6QinlRcEi')

  if (isSameError(tronErrors.SWITCH_CAHIN_ERROR, err))
    msg = $t('vnFz85G8VuawEpVp8Axex')

  if (isSameError(tronErrors.GET_CAHIN_ERROR, err))
    msg = $t('zcF3KkqxFRvVYmR7YTpZ')

  if (isSameError(tronErrors.NOT_SUPPORTED_CAHIN, err))
    msg = $t('eoAxWmzRww2vexmiwApK')

  if (isSameError(tronErrors.GET_ADDRESS_ERROR, err))
    msg = $t('3xFeK3Yuf2Kp6DpPoqA')

  if (isSameError(tronErrors.GET_BALANCE_ERROR, err))
    msg = $t('tkeSIfMlsMz3Qxia0FjQx')

  if (isSameError(tronErrors.TRANSFER_REJECTED, err))
    msg = $t('yinpPeHbDBwsr9lxy13yA')

  if (isSameError(tronErrors.TRANSFER_ERROR, err))
    msg = $t('tm41OUsNwS4B7IkB1jeu')

  if (isSameError(tronErrors.TRANSACTION_EXPIRATION_ERROR, err))
    msg = $t('nkuxD7Qr8pz8LWiGXhnjQ')

  if (isSameError(tronErrors.WALLET_NOT_ACTIVATED, err))
    msg = $t('AUdgXSOOMymyhIWK9Mg1A')

  if (isSameError(tronErrors.MULTISIGN_ERROR, err))
    msg = $t('7zzZDtzX3LTJcyrIcRPu3')

  if (isSameError(tronErrors.MULTISIGN_ERROR_UNPC, err))
    msg = $t('w8wSlabd68LaH3p-m3eX7')

  if (isSameError(tronErrors.RESOURCE_INSUFFICIENT_ERROR, err))
    msg = $t('necasD5ndOJvyEQtNhj22')

  return msg
}

export function differentFormats(err: any, inputErr: string) {
  const msg = err instanceof Error ? String(err) : typeof err === 'object' ? JSON.stringify(err) : String(err)
  return msg.includes(inputErr)
}

// todo 名称以后再更改
export function throwErrorType(err: any) {
  if (
    differentFormats(err, 'Confirmation declined by user')
    || differentFormats(err, 'cancel')
    || differentFormats(err, 'cancle')
    || differentFormats(err, '拒绝')
    || differentFormats(err, '取消')
  )
    throw tronErrors.TRANSFER_REJECTED(err)
  if (
    differentFormats(err, 'Validate TransferContract error, no OwnerAccount')
  )
    throw tronErrors.WALLET_NOT_ACTIVATED(err)

  throw tronErrors.TRANSFER_ERROR(err)
}
