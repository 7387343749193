import { createRequest } from './open'

 const serviceName = 'open.api';

 /**
 * 查询APIKEY详情
 */
export const apiQuery = createRequest<
  undefined,
  { code: number; data: IApiXiangQing; msg: string; request_id: string }
>("apiQuery", () => ({ url: `/v2/api/query`, method: "GET", headers: { "Service-Name": serviceName } }));

/**
 * 查询APIKEY详情
 */
export const apiQueryV1 = createRequest<
  undefined,
  { code: number; data: IApiXiangQing; msg: string; request_id: string }
>("apiQueryV1", () => ({ url: `/v1/api/query`, method: "GET", headers: { "Service-Name": serviceName } }));

/**
 * 出售（委托）订单
 */
export const orderDelegate = createRequest<
  {
    requestBody?: {
      order_no: string;
      resource_value: number;
      settle_address: string;
      signed_txn: IWeiTuoYiQianMingJiaoYi;
    };
  },
  {
    code: number;
    data: {
      order: IMaiJiaDingDanXiangQing;
      tx: { bandwidth_point: number; result: { result: boolean }; transaction_hash: string };
    };
    msg: string;
    request_id: string;
  }
>("orderDelegate", ({ requestBody }) => ({
  url: `/v2/order/delegate`,
  method: "POST",
  data: requestBody,
  headers: { "Content-Type": "application/json", "Service-Name": serviceName },
}));

/**
 * 预估能量消耗
 */
export const orderEstimateEnergy = createRequest<
  {
    contract_address?: string;
    from_address: string;
    to_address: string;
  },
  {
    code: number;
    data: { contract_address: string; energy_used: number; fee: number; from_address: string; to_address: string };
    msg: string;
    request_id: string;
  }
>("orderEstimateEnergy", ({ from_address, contract_address, to_address }) => ({
  url: `/v2/order/estimate_energy`,
  method: "GET",
  params: {
    from_address,
    contract_address,
    to_address,
  },
  headers: { "Service-Name": serviceName },
}));

/**
 * 查询租用订单列表\n* @deprecated orderList
 */
export const orderList = createRequest<
  {
    page?: number;
    page_size?: number;
    status?: string;
  },
  { code: number; data: null | IZuYongDingDanXiangQing[]; msg: string; pagination: IPagination; request_id: string }
>("orderList", ({ page, page_size, status }) => ({
  url: `/v2/order/list`,
  method: "GET",
  params: {
    page,
    page_size,
    status,
  },
  headers: { "Service-Name": serviceName },
}));

/**
 * 查询租用订单列表
 */
export const orderListV1 = createRequest<
  {
    page?: number;
    page_size?: number;
    status?: string;
  },
  { code: number; data: null | IZuYongDingDanXiangQing[]; msg: string; pagination: IPagination; request_id: string }
>("orderListV1", ({ page, page_size, status }) => ({
  url: `/v1/order/list`,
  method: "GET",
  params: {
    page,
    page_size,
    status,
  },
  headers: { "Service-Name": serviceName },
}));

/**
 * 计算订单价格
 */
export const orderPrice = createRequest<
  {
    rent_duration: string;
    rent_time_unit?: string;
    resource_value: number;
  },
  {
    code: number;
    data: {
      pay_amount: number;
      price_in_sun: number;
      rent_duration: number;
      rent_time_unit: string;
      resource_value: number;
    };
    msg: string;
    request_id: string;
  }
>("orderPrice", ({ resource_value, rent_time_unit, rent_duration }) => ({
  url: `/v2/order/price`,
  method: "GET",
  params: {
    resource_value,
    rent_time_unit,
    rent_duration,
  },
  headers: { "Service-Name": serviceName },
}));

/**
 * 查询租用订单
 */
export const orderQuery = createRequest<
  {
    order_no?: string;
  },
  { code: number; data?: null | IZuYongDingDanXiangQing; msg: string; request_id: string }
>("orderQuery", ({ order_no }) => ({
  url: `/v2/order/query`,
  method: "GET",
  params: {
    order_no,
  },
  headers: { "Service-Name": serviceName },
}));

/**
 * 查询租用订单(支付HASH)
 */
export const orderQueryByTxId = createRequest<
  {
    txid: string;
  },
  { code: number; data?: null | IMaiJiaDingDanXiangQing1; msg: string; request_id: string }
>("orderQueryByTxId", ({ txid }) => ({
  url: `/v2/order/query_by_txid`,
  method: "GET",
  params: {
    txid,
  },
  headers: { "Service-Name": serviceName },
}));

/**
 * 查询租用订单
 */
export const orderQueryV1 = createRequest<
  {
    order_no?: string;
  },
  { code: number; data?: IZuYongDingDanXiangQing; msg: string; request_id: string }
>("orderQueryV1", ({ order_no }) => ({
  url: `/v1/order/query`,
  method: "GET",
  params: {
    order_no,
  },
  headers: { "Service-Name": serviceName },
}));

/**
 * 租用下单请求
 */
export const orderSubmit = createRequest<
  {
    requestBody?: {
      receive_address: string;
      rent_duration: number;
      rent_time_second?: number;
      rent_time_unit: string;
      resource_type: number;
      resource_value: number;
    };
  },
  { code: number; data?: IZuYongDingDanXiangQing; msg: string; request_id: string }
>("orderSubmit", ({ requestBody }) => ({
  url: `/v2/order/submit`,
  method: "POST",
  data: requestBody,
  headers: { "Content-Type": "application/json", "Service-Name": serviceName },
}));

/**
 * 租用下单请求
 */
export const orderSubmitV1 = createRequest<
  {
    requestBody?: { receive_address: string; rent_duration: number; resource_type: number; resource_value: number };
  },
  { code: number; data?: IZuYongDingDanXiangQing; msg: string; request_id: string }
>("orderSubmitV1", ({ requestBody }) => ({
  url: `/v1/order/submit`,
  method: "POST",
  data: requestBody,
  headers: { "Content-Type": "application/json", "Service-Name": serviceName },
}));

/**
 * 进行中的订单
 */
export const orderTrades = createRequest<
  {
    page?: number;
    sort: number;
  },
  { code: number; data: null | IMaiJiaDingDanXiangQing[]; msg: string; request_id: string }
>("orderTrades", ({ sort, page }) => ({
  url: `/v2/order/trades`,
  method: "GET",
  params: {
    sort,
    page,
  },
  headers: { "Service-Name": serviceName },
}));

/**
 * 查询TRX和资源的比例
 */
export const resourceCalculate = createRequest<
  {
    amount?: string;
    symbol?: string;
  },
  { code: number; data: { bandwidth: number; energy: number; symbol: string }; msg: string; request_id: string }
>("resourceCalculate", ({ amount, symbol }) => ({
  url: `/v2/api/calculate`,
  method: "GET",
  params: {
    amount,
    symbol,
  },
  headers: { "Service-Name": serviceName },
}));

/**
 * 查询TRX和资源的比例
 */
export const resourceCalculateV1 = createRequest<
  {
    amount?: string;
    symbol?: string;
  },
  { code: number; data: { bandwidth: number; energy: number; symbol: string }; msg: string; request_id: string }
>("resourceCalculateV1", ({ amount, symbol }) => ({
  url: `/v1/api/calculate`,
  method: "GET",
  params: {
    amount,
    symbol,
  },
  headers: { "Service-Name": serviceName },
}));

/**
 * 查询平台资源预估剩余量
 */
export const resourceValue = createRequest<
  undefined,
  { code: number; data: { bandwidth: number; energy: number }; msg: string; request_id: string }
>("resourceValue", () => ({ url: `/v2/api/resource`, method: "GET", headers: { "Service-Name": serviceName } }));

/**
 * 查询平台资源预估剩余量
 */
export const resourceValueV1 = createRequest<
  undefined,
  { code: number; data: { bandwidth: number; energy: number }; msg: string; request_id: string }
>("resourceValueV1", () => ({ url: `/v1/api/resource`, method: "GET", headers: { "Service-Name": serviceName } }));

export interface IApiXiangQing {
  all_bandwidth_value: number;
  all_consume_trx_amount: number;
  all_energy_value: number;
  bandwidth_surplus: number;
  create_time: number;
  energy_surplus: number;
  id?: number;
  key?: string;
  name: string;
  qps?: number;
  quota?: number;
  recharge_address: string;
  today_bandwidth_value: number;
  today_consume_trx_amount: number;
  today_energy_value: number;
  trx_address: string;
  trx_money: number;
  used?: number;
  user_id?: number;
  yesterday_energy_value: number;
}

export interface IMaiJiaDingDanXiangQing {
  create_time: number;
  freeze_time: number;
  frozen_balance: number;
  frozen_resource_value: number;
  frozen_tx_id?: string;
  max_amount: number;
  max_freeze: number;
  max_payout: number;
  min_amount: number;
  min_freeze: number;
  min_payout: number;
  order_no: string;
  order_type: number;
  owner_address: string;
  price_in_sun: number;
  receive_address: string;
  rent_duration: number;
  rent_expire_time: number;
  rent_time_unit: string;
  resource_split_value: number;
  resource_type: number;
  resource_value: number;
  settle_address?: string;
  settle_amount?: number;
  settle_time?: number;
  status: number;
  unfreeze_time?: number;
}

export interface IMaiJiaDingDanXiangQing1 {
  business_status: number;
  create_time: number;
  freeze_time: number;
  frozen_resource_value: number;
  frozen_tx_id: string;
  is_split: number;
  order_no: string;
  order_type: number;
  pay_amount: number;
  pay_time: number;
  price_in_sun: number;
  receive_address: string;
  refund_amount: number;
  refund_time: number;
  rent_duration: number;
  rent_expire_time: number;
  rent_time_unit: string;
  resource_type: number;
  resource_value: number;
  status: number;
  sub_order?: null | IMaiJiaDingDanXiangQing1[];
}

export interface IOrderInternalDetail {
  check_status: number;
  create_time: number;
  expire_time: number;
  freeze_time: number;
  frozen_balance: number;
  frozen_resource_value: number;
  frozen_tx_id: string;
  is_lock: number;
  is_split: number;
  lock_period: number;
  order_id: number;
  order_no: string;
  order_num: number;
  order_parent_no: string;
  order_scene: number;
  order_type: number;
  owner_address: string;
  receive_address: string;
  rent_expire_time: number;
  rent_time_second: number;
  rent_use_duration: number;
  resource_type: number;
  resource_value: number;
  status: number;
  unfreeze_time: number;
  unfreeze_tx_id: string;
}

export interface IOrderOption {
  amount: number;
  energy: number;
}

export interface IPagination {
  cursor?: string;
  has_more: boolean;
  page: number;
  page_size: number;
  total?: number;
}

export interface IWeiTuoYiQianMingJiaoYi {
  raw_data: {
    contract: {
      parameter?: {
        type_url: string;
        value: { balance: number; lock: boolean; owner_address: string; receiver_address: string; resource: string };
      };
      type?: string;
    }[];
    expiration: number;
    ref_block_bytes: string;
    ref_block_hash: string;
    timestamp: number;
  };
  raw_data_hex: string;
  signature: string[];
  txID: string;
  visible: boolean;
}

export interface IZuYongDingDanWanZhengXiangQing {
  create_time: number;
  expire_time?: number;
  freeze_time: number;
  freeze_type?: number;
  frozen_balance?: number;
  frozen_balance_decimals?: number;
  frozen_block_no?: number;
  frozen_confirmed?: number;
  frozen_duration?: number;
  frozen_energy_fee?: number;
  frozen_energy_usage_total?: number;
  frozen_fee?: number;
  frozen_net_fee?: number;
  frozen_net_usage?: number;
  frozen_result?: string;
  frozen_status?: number;
  frozen_tx_id: string;
  modify_time?: number;
  order_id?: number;
  order_no: string;
  owner_address: string;
  pay_address?: string;
  pay_amount: number;
  pay_confirmed?: number;
  pay_discount: number;
  pay_energy_fee?: number;
  pay_energy_usage_total?: number;
  pay_expiration?: number;
  pay_fee?: number;
  pay_net_fee?: number;
  pay_net_usage?: number;
  pay_result?: string;
  pay_service_amount?: number;
  pay_status?: number;
  pay_symbol: string;
  pay_time: number;
  pay_tx_id?: string;
  receive_address: string;
  refund_amount?: number;
  refund_remark?: string;
  refund_time?: number;
  rent_duration: number;
  rent_expire_time: number;
  resource: string;
  resource_type: number;
  resource_value: number;
  status: number;
  user_id?: number;
}

export interface IZuYongDingDanXiangQing {
  create_time: number;
  freeze_time: number;
  frozen_balance?: number;
  frozen_balance_decimals?: number;
  frozen_status?: number;
  order_no: string;
  owner_address?: string;
  pay_address?: string;
  pay_amount: number;
  pay_result?: string;
  pay_status?: number;
  pay_symbol?: string;
  pay_time: number;
  pay_tx_id?: string;
  price_in_sun: number;
  receive_address: string;
  refund_amount?: number;
  refund_remark?: string;
  refund_time?: number;
  rent_duration: number;
  rent_expire_time: number;
  resource_type: number;
  resource_value: number;
  status: number;
}
