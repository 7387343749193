import pkg from '../../package.json'
import fp from '~/composables/useFingerprint'

export async function getReqHeaders() {
  return {
    'lang': unref(useNuxtApp()?.$i18n.locale) === 'zh' ? 'zh-CN' : 'en-US',
    'version': pkg.version,
    'Accept-Date': currentTimeZone(),
    'Device': await fp.getDeviceInfo(),
  }
}
