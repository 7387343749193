import type { Event, EventHint, Severity, SeverityLevel } from '@sentry/types'
import type { ExclusiveEventHintOrCaptureContext } from '@sentry/core/types/utils/prepareEvent'
import * as Sentry from '@sentry/vue'
import type { SentryScopeType } from '~/utils/sentry/types/sentryScopeType'
import type { SentryClassifyType } from '~/utils/sentry/types/sentryClassifyType'

export type TagType = {
  [key in SentryScopeType]?: string | SentryScopeType | SentryClassifyType
}

export class SentryUtils {
  constructor(scope: SentryScopeType) {
    this.setTag('scope', scope)
  }

  private _tags: TagType = <TagType>{
    scope: 'buy',
  }

  setTag(key: SentryScopeType, value: string | SentryScopeType | SentryClassifyType): void {
    this._tags[key] = value
  }

  setTagPath(key: SentryScopeType, path: SentryClassifyType[]): void {
    this.setTag(key, path.join('.'))
  }

  deleteTag(key: SentryScopeType): void {
    delete this._tags[key]
  }

  captureException(exception: any, hint?: ExclusiveEventHintOrCaptureContext): string {
    return Sentry.withScope((scope) => {
      scope.setTags(this._tags)
      return scope.captureException(exception, hint)
    })
  }

  captureMessage(message: string, captureContext: Severity | SeverityLevel = 'error'): string {
    return Sentry.withScope((scope) => {
      scope.setTags(this._tags)
      return scope.captureMessage(message, captureContext)
    })
  }

  captureEvent(event: Event, hint?: EventHint): string {
    return Sentry.withScope((scope) => {
      scope.setTags(this._tags)
      return scope.captureEvent(event, hint)
    })
  }
}
